<template>
    <div class="view home">
		<div class="section side" v-if="isMobile">
			<div class="side-content">
				<div class="collection">
					2019 - 2020<br>
					Black Pug Studio Collection
				</div>
				<img src="@/assets/images/bps_logo_store.svg" alt="Merch Store" class="store-logo">
				<h3>Gear up your inner pug!</h3>
			</div>
			<div ref="discover" class="side-discover">
				<p>Discover the collection</p>
			</div>
		</div>
		<div class="sticker" v-else>
			<div class="sticker-content">
				<h4>SUNNY SEASON HATS</h4>
				<p>Super stylish, protect yourself from sunburns!</p>
			</div>
			<i class="ic ic-chevron-r"></i>
		</div>
		<div class="shadow"></div>
		<div class="categories" v-if="isMobile">
			<div
				v-for="category in categories"
				:key="category.slug"
				class="section category"
				:style="{backgroundImage: `url(${category.image})`}"
			>
				<router-link :to="`/categories/${category.slug}`">
					<h2>{{category.name}}</h2>
					<button><i class="ic ic-arrow-right"></i></button>
				</router-link>
			</div>
		</div>
		<div class="categories" v-else :selected="hovered !== -1" @mouseleave="setCategoryPosition(-1)">
			<router-link
				v-for="(category, i) in categories"
				:key="category.slug"
				class="category"
				ref="category"
				:to="`/categories/${category.slug}`"
				:disabled="category.disabled"
				@mouseenter.native="setCategoryPosition(i)"
			>
				<div class="category-container">
					<div class="category-content" :style="{backgroundImage: `url(${category.image})`}">
						<div class="category-name">
							<h2>{{category.name}}</h2>
						</div>
					</div>
				</div>
			</router-link>
		</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: "home",
	components: {},
	computed: {
		...mapGetters({
			categories: 'getCategories',
			variants: 'getVariants',
			cart: 'getCart',
			isMobile: 'getMobile'
		})
	},
	data() {
		return {
			hovered: -1,
		}
	},
	watch: {
		categories() {
			this.setCategoryPosition(-1)
		}
	},
	methods: {
		formatPrice(p) {
			if (isNaN(p)) return "0.00"
			return parseFloat(p).toFixed(2)
		},
		setCategoryPosition(hovered) {
			clearTimeout(this.indexTO)
			this.hovered = hovered
			const categories = this.$refs.category || []
			categories.map((category, i) => {
				let left = 0
				if(hovered === -1) left = 66.66666 * i
				else if(hovered === i) left = i * 50
				else if(hovered < i) left = (i * 50) + 50
				else if(hovered > i) left = (i * 50)// - 50
				category.$el.style.zIndex = i + 1,
				category.$el.style.transform = `translateX(${left}%)`
				if(i === hovered) {
					category.$el.setAttribute('hovered', '')
				}
				else {
					if(hovered !== -1) category.$el.setAttribute('unselected', '')
					category.$el.removeAttribute('hovered')
				}
				if(hovered === -1 || i === hovered) category.$el.removeAttribute('unselected')
			})
			if(hovered !== -1) {
				this.indexTO = setTimeout(() => {
					this.$refs.category[hovered].$el.style.zIndex = this.$refs.category.length + 1
				}, 320)
			}
		},
		setDiscoverPosition() {
			const category = document.querySelector('.section.side')
			if(category && this.$refs.discover) {
				this.$refs.discover.style.transform = `translateY(${window.innerHeight - 94 - category.clientHeight}px)`
			}
		}
	},
	mounted: function() {
		if(this.isMobile) {
			window.addEventListener('resize', () => {
				this.setDiscoverPosition()
			})
			this.setDiscoverPosition()
		}
		else this.setCategoryPosition(-1)
	}
}
</script>

<style scoped>
.sticker {
	position: absolute;
	width: 10.83vw;
	right: 62vw;
	top: 5.93vh;
	z-index: 3;
}

.sticker .sticker-content {
	position: relative;
	padding: 1.11vh .83vw 2.22vh;
	overflow: hidden;
}

.sticker .sticker-content:before,
.sticker .sticker-content:after {
	content: '';
	border: 2px solid #208E7F;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
}

.sticker .sticker-content:after {
	width: 100px;
	height: 100px;
	left: 88%;
	top: 88%;
	z-index: 1;
	background: #221F1F;
	transform: rotateZ(45deg);
}

.sticker h4 {
	width: 12.5vh;
	font-weight: 900;
	font-size: 2.96vh;
	line-height: .8125;
	text-transform: uppercase;
	margin-bottom: .74vh;
}

.sticker p {
	width: 12.5vh;
	font-size: 1.48vh;
	font-family: 'Inter';
	opacity: .77;
}

.sticker .ic {
	position: absolute;
	top: 3.33vh;
	left: 100%;
}

.categories {
	position: absolute;
	display: flex;
	top: 2.59vh;
	right: 0;
	bottom: 0;
	width: 60.2vw;
	z-index: 3;
	overflow: hidden;
}

.categories .category {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 50%;
	height: 100%;
	border: none;
	border-radius: 0;
	transition: transform .32s ease-out;
	/* overflow: hidden; */
	flex-shrink: 0;
	will-change: transform;
}

.categories .category .category-container {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.categories .category .category-content {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100%;
	transform: translateX(-16.66666%);
	transition: transform .32s ease-out;
	will-change: transform;
}

.categories .category .category-content:after {
	content: '';
	position: absolute;
	top: 0;
	right: -16px;
	bottom: 0;
	width: 86px;
	background: rgb(34,31,31);
	background: -moz-linear-gradient(270deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
	background: -webkit-linear-gradient(270deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
	background: linear-gradient(270deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#221f1f",endColorstr="#221f1f",GradientType=1);
	transform: translateX(-5vw);
	transition: transform .32s ease-out;
	/* transition-delay: .08s; */
	will-change: transform;
	opacity: .7;
}

.categories .category[hovered] .category-content:after {
	transform: none;
	/* transition-delay: .08s; */
}

.categories .category[unselected] .category-content:after {
	transform: translateX(-7vw);
	transition-delay: .08s;
}

.categories[selected] .category .category-content {
	transform: translateX(-25%);
}

.categories .category[hovered] .category-content {
	transform: none;
}

.categories .category .category-name {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: left;
	transform: translateX(72%) rotateZ(-90deg);
	transform-origin: top left;
	transition: transform .32s ease-out;
	z-index: 2;
}

.categories .category:hover .category-name {
	transform: translateX(89%) rotateZ(-90deg);
}

.categories .category[unselected] .category-name {
	transform: translateX(64%) rotateZ(-90deg);
}

.categories .category .category-name h2 {
	display: inline-block;
	font-size: 1.98vw;
	font-weight: 900;
	letter-spacing: -.08em;
	text-transform: uppercase;
	white-space: nowrap;
	padding: 0;
}

@media screen and (max-width: 1023px) {
	.view .section {
		position: relative;
		width: auto;
		height: calc(100vh - 94px);
		bottom: auto;
		left: auto;
		right: auto;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 0 32px;
		/* transition: height .32s; */
	}
	.categories {
		position: relative;
		display: block;
		top: 0;
		width: auto;
		z-index: 2;
	}
	.side {
		background-image: url(../assets/images/bps_m_bg_home@2x.jpg);
	}
	.side:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 414px;
		background: rgb(34,31,31);
		background: -moz-linear-gradient(180deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		background: -webkit-linear-gradient(180deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		background: linear-gradient(180deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#221f1f",endColorstr="#221f1f",GradientType=1);
	}
	.side .side-content {
		padding: 24px 16px;
	}
	.side .collection {
		font-size: 12px;
		margin-bottom: 8px;
	}
	.side .store-logo {
		height: 92px;
	}
	.side h3 {
		font-size: 18px;
		margin-top: 8px;
	}
	.side .side-discover {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 10px;
		font-family: 'Inter';
		text-transform: uppercase;
		transition: transform .16s;
	}
	.side .side-discover:after {
		content: '';
		display: block;
		width: 12px;
		height: 8px;
		margin: 4px auto 0;
		background: url(../assets/images/bps_i_chevron-bottom.svg) no-repeat center/contain;
	}
	.categories .category {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
	}
	.categories .category:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 148px;
		background: rgb(34,31,31);
		background: -moz-linear-gradient(0deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		background: -webkit-linear-gradient(0deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		background: linear-gradient(0deg, rgba(34,31,31,1) 0%, rgba(34,31,31,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#221f1f",endColorstr="#221f1f",GradientType=1);
		opacity: .7;
	}
	.categories .category a {
		position: relative;
		display: flex;
		max-width: 350px;
		padding: 24px 0;
		z-index: 2;
	}
	.categories .category h2 {
		flex-grow: 1;
		font-weight: 900;
		font-size: 42px;
		text-transform: uppercase;
		letter-spacing: -.08em;
		line-height: 37px;
		white-space: pre-line;
	}
	.categories .category button {
		padding: 0;
		background: #208E7F;
		border: 4px solid #155C52;
		width: 48px;
		height: 48px;
		flex-shrink: 0;
		margin-left: 48px;
	}
	.view .shadow {
		position: fixed;
		width: 100%;
		height: 314px;
		left: 0;
		bottom: -157px;
		background: rgb(32,142,127);
		background: -moz-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		background: -webkit-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		background: radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#208e7f",endColorstr="#208e7f",GradientType=1);
		pointer-events: none;
	}
}

@media screen and (max-width: 410px) {
	.categories .category h2 {
		font-size: 28px;
		line-height: 30px;
	}
}
</style>

