<template>
  <div id="app" :page="$route.name" :menu-opened="menuOpened" :cart-opened="cartOpened && $route.name !== 'cart'" :overlay="Boolean(dialog)" :newsletter="newsletterBar && (!subscribed || isMobile)" :loader="!cart || !variants || !variants.length || loading" @click="newsletterBar = isMobile ? false : newsletterBar">
		<header>
			<button v-if="isMobile && $route.name !== 'checkout'" class="btn" @click="menuOpened = true"><i class="ic ic-menu"></i></button>
			<a @click="leaveSite"><img src="@/assets/images/bps_logo.svg" alt="Black Pug Studio"></a>
			<button v-if="isMobile && $route.name !== 'checkout'" class="btn" @click="cartOpened = true"><i class="ic ic-cart"></i></button>
			<div class="header-actions" v-else-if="!isMobile">
				<router-link to="/cart">
					<span>Cart<i v-if="cart && cart.item_count"> ({{cart.item_count}} item{{cart.item_count > 1 ? 's' : ''}})</i></span>
					<i class="ic ic-l ic-cart-circle"></i>
					<span class="badge" v-if="cart && cart.item_count">{{cart.item_count}}</span>
				</router-link>
			</div>
			<router-link v-else to="/"><img src="@/assets/images/bps_logo_store.svg" alt="Merch Store" class="store-logo"></router-link>
		</header>
		<main>
			<router-view></router-view>
		</main>
		<div class="side" v-if="!isMobile">
			<Fireflies v-if="isMobile === false" />
			<div class="side-content" v-if="$route.name === 'home'">
				<div class="collection">
					2019 - 2020<br>
					Black Pug Studio Collection
				</div>
				<img src="@/assets/images/bps_logo_store.svg" alt="Merch Store" class="store-logo">
				<h3>Gear up your inner pug!</h3>
			</div>
			<a v-else class="back" @click="goBack">
				<i class="ic ic-chevron-l"></i>
				<div>
					Back
					<!-- <img src="@/assets/images/bps_logo_store.svg" alt="Merch Store" class="store-logo"> -->
				</div>
			</a>
		</div>
		<footer>
			<div class="socials">
				<a rel="noopener" href="https://www.behance.net/BlackPugStudio" class="ic ic-s ic-behance social" target="_blank"></a>
				<a rel="noopener" href="https://twitter.com/BlackPugStudio" class="ic ic-s ic-twitter social" target="_blank"></a>
				<a rel="noopener" href="https://www.instagram.com/blackpugstudio/" class="ic ic-s ic-instagram social" target="_blank"></a>
				<a rel="noopener" href="https://medium.com/creative-black-pug-studio" class="ic ic-s ic-medium social" target="_blank"></a>
			</div>
			<div class="copyright">©&nbsp;<date-format :date="new Date()" format="%Y"></date-format>&nbsp;- Black Pug Studio Limited - All rights reserved</div>
			<div class="note">Black Pug Studio - Merch Store</div>
		</footer>
		<nav ref="nav" class="menu" v-if="isMobile">
			<div class="menu-header">
				<button class="btn" @click="menuOpened = false"><i class="ic ic-chevron-left"></i> Back</button>
			</div>
			<div class="menu-links">
				<router-link class="menu-link" to="/" @click.native="menuOpened = false">
					<span><i class="ic ic-paw"></i>Home</span>
					<button class="btn"><i class="ic ic-chevron-right"></i></button>
				</router-link>
				<router-link class="menu-link" :to="`/categories/${category.slug}`" v-for="category in categories" :key="category.name" @click.native="menuOpened = false">
					<span><i class="ic ic-paw"></i>{{category.name}}</span>
					<button class="btn"><i class="ic ic-chevron-right"></i></button>
				</router-link>
			</div>
			<div class="menu-cart">
				<router-link to="/cart">Your cart<i class="ic ic-cart-circle"></i></router-link>
			</div>
			<div class="menu-logos">
				<router-link to="/" @click.native="menuOpened = false">
					<img class="merch-logo" src="@/assets/images/bps_logo_store.svg" alt="Merch Store">
				</router-link>
				<a @click="leaveSite">
					<img src="@/assets/images/bps_logo.svg" alt="Black Pug Studio">
				</a>
			</div>
			<footer>
				<div class="socials">
					<a rel="noopener" href="https://www.behance.net/BlackPugStudio" class="ic ic-s ic-behance social" target="_blank"></a>
					<a rel="noopener" href="https://twitter.com/BlackPugStudio" class="ic ic-s ic-twitter social" target="_blank"></a>
					<a rel="noopener" href="https://www.instagram.com/blackpugstudio/" class="ic ic-s ic-instagram social" target="_blank"></a>
					<a rel="noopener" href="https://medium.com/creative-black-pug-studio" class="ic ic-s ic-medium social" target="_blank"></a>
				</div>
				<div class="copyright">©&nbsp;<date-format :date="new Date()" format="%Y"></date-format>&nbsp;- Black Pug Studio Limited - All rights reserved</div>
				<div class="note">Black Pug Studio - Merch Store</div>
			</footer>
		</nav>
		<nav class="cart-panel" v-if="isMobile">
			<div class="menu-header">
				<div class="menu-title">
					<i class="ic ic-cart"></i>
					<span>Your shopping cart</span>
				</div>
				<button class="btn" @click="cartOpened = false"><i class="ic ic-close"></i></button>
			</div>
			<Cart />
			<footer>
				<div class="socials">
					<a rel="noopener" href="https://www.behance.net/BlackPugStudio" class="ic ic-s ic-behance social" target="_blank"></a>
					<a rel="noopener" href="https://twitter.com/BlackPugStudio" class="ic ic-s ic-twitter social" target="_blank"></a>
					<a rel="noopener" href="https://www.instagram.com/blackpugstudio/" class="ic ic-s ic-instagram social" target="_blank"></a>
					<a rel="noopener" href="https://medium.com/creative-black-pug-studio" class="ic ic-s ic-medium social" target="_blank"></a>
				</div>
				<div class="copyright">©&nbsp;<date-format :date="new Date()" format="%Y"></date-format>&nbsp;- Black Pug Studio Limited - All rights reserved</div>
				<div class="note">Black Pug Studio - Merch Store</div>
			</footer>
		</nav>
		<div class="toast" :opened="toastOpened">
			<div class="toast-header">
				<div class="toast-title">Restore my cart</div>
			</div>
			<div class="toast-content">
				<p>We’re so nice that we saved your cart from your previous visit.<br>Would you like to continue your shopping and take it where you left it last time?</p>
			</div>
			<div class="toast-actions">
				<button class="btn clear" @click="cancelCart">Nah, I'll start over!</button>
				<button class="btn valid" @click="retrieveCart">Yep, thanks!</button>
			</div>
		</div>
		<div class="overlay" @click="dialog = null">
			<div class="dialog" v-if="dialog" @click.stop>
				<div class="dialog-container">
					<div class="dialog-title"><i class="ic ic-warning"></i>{{dialog.title}}</div>
					<div class="dialog-content" v-html="dialog.message"></div>
				</div>
				<div class="dialog-actions">
					<a v-for="(button, i) in dialog.buttons" :key="`dialog-btn-${i}`" :class="`btn ${button.class || ''}`" @click="() => button.action()">{{button.text}}</a>
				</div>
			</div>
		</div>
		<div class="loader" :active="loading">
			<img class="main-logo" src="@/assets/images/bps_logo.svg" alt="Black Pug Studio">
			<img src="@/assets/images/bps_loader.gif" alt="BPS Merch Store loader">
			<!-- <svg xmlns="http://www.w3.org/2000/svg" width="27.426" height="29.318" viewBox="0 0 27.426 29.318">
				<g transform="translate(-407.394 -310.849)">
					<g transform="translate(409.352 284.088)">
						<path class="s4" d="M8.373,26.954c-1.39.973-.278,5.7,1.806,8.477.695.973,1.806,2.5,3.335,2.5a3.1,3.1,0,0,0,2.362-1.251,3.915,3.915,0,0,0,.417-2.918C15.6,29.733,10.04,25.842,8.373,26.954Z" fill="#fff"/>
					</g>
					<g transform="translate(407.681 284.874)">
						<path class="s3" d="M5.6,28.808c-2.084.556-3.2,6.809-.695,10.144.278.278,1.806,2.362,3.613,1.945a2.8,2.8,0,0,0,1.668-1.112c.973-1.251.695-3.057.556-3.891C10.183,32.421,7.4,28.391,5.6,28.808Z" fill="#fff"/>
					</g>
					<g transform="translate(406.629 287.323)">
						<path class="s2" d="M2.481,35.113C.675,35.669-.3,42.339,2.62,44.7c.139.139,1.668,1.39,3.057.973s1.945-2.223,1.945-2.223a3.3,3.3,0,0,0-.139-2.084C6.65,39,4.149,34.558,2.481,35.113Z" fill="#fff"/>
					</g>
					<g transform="translate(411.929 285.155)">
						<path class="s5" d="M18.3,38.671a2.716,2.716,0,0,0,2.084-.973,3.216,3.216,0,0,0,.417-2.64c-.417-3.2-4.308-6.253-5.7-5.419-1.251.695-.834,4.586.695,6.948C16.357,37.421,17.052,38.532,18.3,38.671Z" fill="#fff"/>
					</g>
					<g transform="translate(408.518 287.596)">
						<path class="s1" d="M16.433,37.481a2.44,2.44,0,0,1-.556-.695c-.695-.973-2.5-1.251-3.891-.834a2.761,2.761,0,0,0-1.251.834,1.975,1.975,0,0,0-.278.834l-.139.973v1.529a1.847,1.847,0,0,1-.417.973,2.762,2.762,0,0,1-1.251.834c-1.945.834-2.779,3.474-2.779,3.752a4.892,4.892,0,0,0-.139,2.64,5.326,5.326,0,0,0,2.779,3.613,4,4,0,0,0,1.529.556,4.546,4.546,0,0,0,2.64-.278,7.5,7.5,0,0,0,2.64-1.945c.139-.139.139-.278.278-.417a6,6,0,0,1,2.779-2.918,4.754,4.754,0,0,1,1.529-.278h1.668a5.7,5.7,0,0,0,1.529-.139,5.8,5.8,0,0,0,1.39-.695,3.085,3.085,0,0,0,1.251-1.39,4.2,4.2,0,0,0,.556-2.084,4.239,4.239,0,0,0-1.251-3.474,3.6,3.6,0,0,0-2.362-.834c-.973,0-1.251.417-3.057.556a3.848,3.848,0,0,1-1.806-.139C17.128,38.037,16.711,37.62,16.433,37.481Z" fill="#fff"/>
					</g>
				</g>
			</svg> -->
		</div>
		<button class="newsletter-thumb" v-if="!isMobile && !subscribed" @click="newsletterBar = true">
			<div class="newsletter-teaser"><i class="ic ic-ticket"></i>Get 5% discount</div>
		</button>
		<div class="newsletter-bar" @click.stop="newsletterBar = true" :success="newsletterSuccess || subscribed">
			<div class="newsletter-teaser"><i v-if="!isMobile" class="ic ic-ticket"></i>Get 5% discount <br v-if="isMobile">on your next purchase!</div>
			<form @submit.prevent="subscribeNewsletter">
				<input v-model="newsletterEmail" placeholder="Enter your email here..." type="email" required>
				<button type="submit"></button>
			</form>
			<div class="newsletter-disclaimer">
				Subscribe to our newsletter to receive a 5% discount on your next purchase!<br>
				Don’t worry, we’ll only send you updates once in a while. We are like you, we don’t like spam!
			</div>
			<button v-if="!isMobile" class="ic ic-close" @click.stop="newsletterBar = false"></button>
			<div class="newsletter-confirmation">
				<p>Thank you for subscribing to our newsletter!<br>You will receive your 5% discount coupon in a minute.</p>
			</div>
		</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DateFormat from './components/DateFormat'
import Fireflies from './components/Fireflies'
import Cart from './views/Cart'

export default {
	name: "app",
	components: {
		DateFormat,
		Fireflies,
		Cart
	},
	computed: {
		...mapGetters({
			variants: 'getVariants',
			cart: 'getCart',
			categories: 'getCategories',
			isMobile: 'getMobile',
			functions: 'getFunctions'
		})
	},
	watch: {
		menuOpened(opened, previous) {
			clearTimeout(this.menuTO)
			if(!previous) this.scrollValue = document.body.scrollTop || window.scrollY
			this.menuTO = setTimeout(() => {
				document.body.style.overflow = document.documentElement.style.overflow = opened ? 'hidden' : null
				if(!opened) window.scrollTo(0, this.scrollValue)
			}, opened ? 240 : 0)
		},
		cartOpened(opened, previous) {
			if(this.$route.name === 'cart') return
			clearTimeout(this.menuTO)
			if(!previous) this.scrollValue = document.body.scrollTop || window.scrollY
			this.menuTO = setTimeout(() => {
				document.body.style.overflow = document.documentElement.style.overflow = opened ? 'hidden' : null
				if(!opened) window.scrollTo(0, this.scrollValue)
			}, opened ? 240 : 0)
		},
		$route(to, from) {
			if(!from || !to || !from.name || !to.name || (to.name !== from.name && !to.name.includes(from.name) && !from.name.includes(to.name))) {
				// this.loading = true
				if(!this.backing && from && from.path && (!to || !to.path || to.path !== '/404')) this.history.push(from.path)
				this.previousPage = this.history[this.history.length - 1] || '/'
				this.pageChanged(to)
			}
			setTimeout(() => {
				this.menuOpened = this.cartOpened = false
			}, 240)
		},
		variants() {
			setTimeout(() => {
				if(window.loadCartItems().length) this.toastOpened = true
			}, 2000)
		}
	},
	data() {
		return {
			previousPage: '/',
			menuOpened: false,
			scrollValue: 0,
			cartOpened: false,
			loading: true,
			toastOpened: false,
			history: [],
			dialog: null,
			newsletterEmail: '',
			newsletterBar: false,
			newsletterSuccess: false,
			subscribed: Boolean(window.localStorage && window.localStorage.getItem('newsletter_sub')),
			// subscribed: true
		}
	},
	methods: {
		pageChanged(to) {
			if(to.name === 'home') {
				Promise.all(this.categories.map(c => {
					return new Promise((resolve, reject) => {
						const img = new Image()
						img.onload = () => resolve()
						img.onerror = () => reject()
						img.src = c.image
					})
				}))
				.then(() => this.loading = false)
				.catch(() => this.loading = false)
			}
			if(!['product', 'product-variant', 'alt-product', 'alt-product-variant'].includes(to.name)) {
				document.title = 'Black Pug Studio Merch Store'
			}
		},
		subscribeNewsletter(e) {
			const target = e.target
			target.removeAttribute('error')
			target.setAttribute('loading', '')
			this.functions.httpsCallable('subscribeNewsletter')({email: this.newsletterEmail})
				.then(() => {
					target.removeAttribute('loading')
					this.newsletterEmail = ''
					if(window.localStorage) window.localStorage.setItem('newsletter_sub', '1')
					this.newsletterSuccess = true
				})
				.catch(() => {
					target.setAttribute('error', '')
					this.dialog = {
						title: 'Hey!',
						message: '<p>Check your emails, we have already sent a discount code to this address :)</p>',
						buttons: [
							{
								text: 'OK',
								class: 'valid',
								action: () => this.dialog = null
							},
						]
					}
					target.removeAttribute('loading')
				})
		},
		goBack() {
			this.backing = true
			this.$router.push(this.history.pop() || '/')
			setTimeout(() => this.backing = false, 200)
		},
		leaveSite() {
			this.dialog = {
				title: 'Oops! You’re about to leave the store…',
				message: '<p>Are you sure you want to leave the Black Pug Studio Merch Store?</p>',
				buttons: [
					{
						text: 'Yes, set me free!',
						action: () => window.location.href = `https://www.blackpugstudio.com`
					},
					{
						text: 'Nope, I’m staying',
						class: 'valid',
						action: () => this.dialog = null
					},
				]
			}
		},
		cancelCart() {
			this.$store.commit('emptyCart')
			this.toastOpened = false
		},
		retrieveCart() {
			this.$store.commit('retrieveCart')
			this.toastOpened = false
		},
		formatPrice(p) {
			if (isNaN(p)) return "0.00"
			return parseFloat(p).toFixed(2)
		},
	},
	mounted: function() {
		window.addEventListener('closePanel', () => {
			this.menuOpened = this.cartOpened = false
		})
		window.addEventListener('openCart', () => {
			this.menuOpened = false
			this.cartOpened = true
		})
		window.addEventListener('setLoading', e => {
			this.loading = e.detail.value
		})
		window.addEventListener('returnPolicy', () => {
			this.dialog = {
				title: 'Our Return Policy',
				message: `
				<p><b>Return Policy</b><br>
Any claims for misprinted/damaged/defective items must be submitted within 4 weeks after the product has been received. For packages lost in transit, all claims must be submitted no later than 4 weeks after the estimated delivery date. Claims deemed an error on our part are covered at the expense of Printful.</p><br>

<p><b>Wrong Address -</b> If you provide an address that is considered insufficient by the courier, the shipment will be returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you.</p><br>

<p><b>Unclaimed -</b> Shipments that go unclaimed are returned to our facility and you will be liable for the cost of a reshipment to yourself or your end customer. Unclaimed returns get donated to charity after 4 weeks.</p><br>

<p><b>Returns –</b> Please contact Black Pug Studio before returning any products. We can’t refund orders for buyer’s remorse, but in the case that you require a size exchange and cannot comfortably wear the apparel, please contact us on our website.</p><br>

<p><b>Notification for EU consumers:</b> According to Article 16(c) of the Directive 2011/83/EU of the European Parliament and of the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for the supply of goods made to the consumer's specifications or clearly personalized, therefore Printful reserves rights to refuse returns at its sole discretion.<br>
This Policy shall be governed and interpreted in accordance with the English language, regardless of any translations made for any purpose whatsoever.</p><br>
Packages can be returned at this address:<br><br>
Starta 1<br>
2nd bldg., 3rd fl.<br>
Riga, Latvia<br>
LV-1026`,
				buttons: [
					{
						text: 'OK',
						class: 'valid',
						action: () => this.dialog = null
					},
				]
			}
		})
		setTimeout(() => {
			this.pageChanged(this.$route)
		}, 500)
		// if(!this.isMobile) this.newsletterBar = true
		// setTimeout(() => this.loading = false, 3000)
	}
}
</script>

<style>
@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  src: url("./assets/fonts/Inter-Thin-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-Thin-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  src: url("./assets/fonts/Inter-ThinItalic-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  src: url("./assets/fonts/Inter-ExtraLight-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-ExtraLight-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  src: url("./assets/fonts/Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  src: url("./assets/fonts/Inter-Light-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-Light-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  src: url("./assets/fonts/Inter-LightItalic-BETA.woff2") format("woff2"),
       url("./assets/fonts/Inter-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url("./assets/fonts/Inter-Regular.woff2") format("woff2"),
       url("./assets/fonts/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  src: url("./assets/fonts/Inter-Italic.woff2") format("woff2"),
       url("./assets/fonts/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url("./assets/fonts/Inter-Medium.woff2") format("woff2"),
       url("./assets/fonts/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  src: url("./assets/fonts/Inter-MediumItalic.woff2") format("woff2"),
       url("./assets/fonts/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"),
       url("./assets/fonts/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  src: url("./assets/fonts/Inter-SemiBoldItalic.woff2") format("woff2"),
       url("./assets/fonts/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url("./assets/fonts/Inter-Bold.woff2") format("woff2"),
       url("./assets/fonts/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  src: url("./assets/fonts/Inter-BoldItalic.woff2") format("woff2"),
       url("./assets/fonts/Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url("./assets/fonts/Inter-ExtraBold.woff2") format("woff2"),
       url("./assets/fonts/Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  src: url("./assets/fonts/Inter-ExtraBoldItalic.woff2") format("woff2"),
       url("./assets/fonts/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url("./assets/fonts/Inter-Black.woff2") format("woff2"),
       url("./assets/fonts/Inter-Black.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  src: url("./assets/fonts/Inter-BlackItalic.woff2") format("woff2"),
       url("./assets/fonts/Inter-BlackItalic.woff") format("woff");
}

* {
	box-sizing: border-box;
	outline: none;
}

[dynamic]:not([style]) {
	transition: transform .24s ease-out;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}

button {
	font: inherit;
	color: inherit;
	cursor: pointer;
	border-radius: 0;
}

button[disabled] {
	opacity: .5;
}

#app {
	display: flex;
	flex-flow: column nowrap;
	padding: 8.88vh 5vw 0;
	height: 100%;
}

@keyframes snapDown {
	0% {
		transform: translateY(-1vh);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-1vh);
	}
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.newsletter-thumb,
.newsletter-bar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 2.48vh 6vw 1.48vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #C9433C;
	transform: translateY(-100%);
	transition: transform .16s ease-in;
	overflow: hidden;
}

.newsletter-bar .newsletter-confirmation {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding-top: 1vh;
	background: #C9433C;
	z-index: 2;
	box-shadow: 32px 0 24px #C9433C;
	transition: transform .5s;
	transform: translateX(-120%);
	font-weight: bold;
}

.newsletter-bar .newsletter-confirmation:after {
	content: '';
	position: absolute;
	left: 100%;
	top: 0;
	bottom: 0;
	width: 64px;
	filter: blur(20px);
}

.newsletter-bar[success] > *:not(.newsletter-confirmation),
.newsletter-bar .newsletter-confirmation p {
	opacity: 0;
	transition: opacity .16s ease-in .5s;
}

.newsletter-bar[success] .newsletter-confirmation {
	transform: none;
}

.newsletter-bar .newsletter-confirmation p {
	opacity: 0;
	transition: opacity .16s ease-in .5s;
}

.newsletter-bar[success] .newsletter-confirmation p {
	opacity: 1;
}

.newsletter-thumb {
	width: 172px;
	height: calc(32px + 1vh);
	margin: auto;
	padding: 1vh 0 0;
	border: none;
	border-radius: none;
}

#app:not([newsletter]) .newsletter-thumb,
#app[newsletter] .newsletter-bar {
	transform: translateY(-1vh);
	animation: snapDown .16s ease-in-out .1s forwards;
}

.newsletter-bar > button.ic {
	position: absolute;
	top: 1vh;
	bottom: 0;
	right: 5vw;
	width: 1.11vh;
	height: 1.11vh;
	padding: 0;
	background-color: transparent;
	border: none;
	margin: auto;
	z-index: 4;
}

.newsletter-thumb .newsletter-teaser,
.newsletter-bar .newsletter-teaser {
	display: flex;
	align-items: center;
	font-weight: 900;
	font-size: .83vw;
	text-transform: uppercase;
}

.newsletter-thumb .newsletter-teaser {
	font-size: 12px;
}

.newsletter-thumb .newsletter-teaser .ic,
.newsletter-bar .newsletter-teaser .ic {
	width: 1.25vw;
	height: 1.25vw;
	margin-right: 4px;
}

.newsletter-thumb .newsletter-teaser .ic {
	width: 14px;
	height: 14px;
}

.newsletter-bar form {
	display: flex;
	margin: 0 1.66vw;
}

.newsletter-bar form input {
	width: 14.27vw;
	padding: 0 8px;
	font-family: 'Inter';
}

.newsletter-bar form[error] input {
	background: #e29b97;
}

.newsletter-bar .newsletter-disclaimer {
	font: 600 .625vw/1.4 'Inter';
}

.newsletter-bar form[loading] {
	opacity: .7;
}

.newsletter-bar form button {
	background: #221F1F;
	font-size: .625vw;
	font-weight: bold;
	width: 6.875vw;
}

.newsletter-bar form button:after {
	content: 'Subscribe'
}

.newsletter-bar form[loading] button:after {
	content: 'Sending...'
}

.newsletter-bar form > * {
	border-radius: 0;
	height: 3.52vh;
	border: none;
}

.overlay {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	z-index: 9999999;
	background: rgba(34, 31, 31, 0.72);
	overflow: hidden;
	opacity: 0;
	transition: opacity .16s ease-out;
}

#app[overlay] .overlay {
	padding: 24px;
	height: 100%;
	opacity: 1;
}

.overlay .dialog {
	width: 478px;
	background: #312C2C;
	font-size: 14px;
	max-width: 100%;
	padding: 26px;
	border: 5px solid #C9433C;
}

.overlay .dialog .dialog-title {
	display: flex;
	align-items: center;
	font-weight: 900;
	font-size: 16px;
	color: #C9433C;
}

.overlay .dialog .dialog-title .ic {
	width: 28px;
	height: 28px;
	margin-right: 8px;
}

.overlay .dialog .dialog-content {
	margin: 4px 0 16px;
	font-family: 'Inter';
	font-size: 12px;
	padding-left: 36px;
}

.overlay .dialog .dialog-actions {
	text-align: center;
}

.overlay .dialog .dialog-actions .btn {
	margin: 0 8px;
	width: 136px;
	height: 38px;
	font-size: 12px;
}

.overlay .dialog .dialog-actions .btn.valid {
	background: #208E7F;
	border-color: #155C52;
}

.toast {
	position: fixed;
	bottom: 7vh;
	left: 0;
	width: 27.6vw;
	background: #312C2C url(./assets/images/bps_i_restore.svg) no-repeat .63vw .63vw/1.25vw auto;
	padding: 1.2vh 1vw 1.2vh 2.3vw;
	border-left: 8px solid #EDBA52;
	transform: translateX(-100%);
	transition: transform .16s ease-out;
	z-index: 101;
}

.toast[opened] {
	transform: none;
	transition-delay: .5s;
}

.toast .toast-content {
	margin: .7vh 0 1vh;
	font-family: 'Inter';
	font-size: .625vw;
}

.toast .toast-actions {
	/* text-align: right; */
}

.toast .toast-title {
	font-weight: 900;
	text-transform: uppercase;
	color: #EDBA52;
	font-size: 1.48vh;
}

.dialog-actions .btn,
.toast .toast-actions button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 12.59vh;
	height: 3.52vh;
	border: none;
	background: none;
	font-weight: bold;
	font-family: 'Inter';
	font-size: 1.11vh;
	transition: opacity .16s;
	/* opacity: .7; */
	border: 4px solid rgba(250, 250, 250, 0.33);
	margin-right: 16px;
}

.dialog-actions .btn.valid,
.toast .toast-actions button.valid {
	border-color: #BA9241;
	background: #EDBA52;
}

.dialog-actions .btn:hover,
.toast .toast-actions button:hover {
	opacity: 1;
}

.loader {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999;
	opacity: 0;
	pointer-events: none;
	transition: opacity .16s ease-in;
}

.loader[active] {
	opacity: 1;
	pointer-events: all;
	transition: none;
}

.loader .main-logo {
	position: absolute;
	top: 48px;
	left: 0;
	right: 0;
	width: 296px;
	margin: auto;
}

/* .loader svg {
	display: block;
	width: 128px;
	height: 128px;
}

.loader svg .s1,
.loader svg .s2,
.loader svg .s3,
.loader svg .s4,
.loader svg .s5 {
	transform: scale(0);
	transition: all .24s ease-in;
	transform-origin: bottom center;
	opacity: 0;
}

.loader svg[active] .s1,
.loader svg[active] .s2,
.loader svg[active] .s3,
.loader svg[active] .s4,
.loader svg[active] .s5 {
	transform: none;
	opacity: 1;
}

.loader svg .s2 {
	transition-delay: .2s;
}

.loader svg .s3 {
	transition-delay: .4s;
}

.loader svg .s4 {
	transition-delay: .6s;
}

.loader svg .s5 {
	transition-delay: .8s;
} */

.side {
	position: absolute;
	bottom: 22.22vh;
	left: 0;
	width: 100%;
	padding-left: 2.4vw;
}

.side .back {
	position: relative;
	display: inline-flex;
	align-items: center;
	margin-bottom: 5vh;
	z-index: 3;
	padding: 8px 0;
}

.side .back img {
	width: 4.17vw;
	height: auto;
}

.side .back i {
	height: .83vw;
	margin-right: .42vw;
	margin-top: 0;
	/* margin-bottom: .52vw; */
}

[page="home"] .side {
	padding-left: 15.88vw;
}

[page="home"] .side:after {
	content: '';
	position: absolute;
	left: 7.08vw;
	width: 6.77vw;
	height: 6.3vh;
	bottom: 8.88vh;
	background: url(assets/images/bps_i_arrow.svg) no-repeat center/contain;
}

.side .fireflies {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 3.88vh;
}

.side .side-content {
	position: relative;
	z-index: 1;
}

.side .collection {
	margin-bottom: 2.22vh;
	text-transform: uppercase;
	color: #208E7F;
	font-family: 'Inter';
	font-weight: 500;
}

.side .store-logo {
	display: block;
	height: 12.03vh;
}

.side h3 {
	font-family: 'Inter';
	font-weight: 400;
	font-size: 2.31vh;
	margin-top: 2.77vh;
}

main {
	position: relative;
	flex-grow: 1;
}

header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 3;
}

header img {
	height: 5.2vh;
}

header .header-actions .ic {
	margin-left: 8px;
}

header .header-actions a {
	position: relative;
	display: inline-flex;
	font-family: 'Inter';
	align-items: center;
	text-transform: uppercase;
	color: #EDBA52;
}

header .header-actions a i {
	font-style: normal;
	text-transform: none;
}

header .header-actions a .badge {
	position: absolute;
	top: -5px;
	right: -5px;
	height: 16px;
	line-height: 16px;
	min-width: 16px;
	font-size: 12px;
	font-family: 'Inter';
	font-weight: 500;
	background: #C9433C;
	color: #fff;
	text-align: center;
	border-radius: 8px;
	padding: 0 4px;
}

.view .view-title {
	text-align: center;
	text-transform: uppercase;
}

.view .view-title h1 {
	font-weight: 900;
	font-size: 2.96vh;
}

.view .view-title h3 {
	font-weight: 400;
	font-size: 1.3vh;
	font-family: 'Inter';
}

.view .view-title .collection {
	color: #208E7F;
	font-size: 1.11vh;
	font-family: 'Inter';
	font-weight: 500;
}

table {
	width: 100%;
	border-collapse: collapse;
}

footer {
	position: absolute;
	left: 5vw;
	right: 150px;
	bottom: 3.33vh;
	display: flex;
	align-items: center;
	font-size: 1.11vh;
	font-family: 'Inter';
	line-height: 1;
	z-index: 4;
}

#app[loader] footer {
	z-index: 99999999;
}

footer .social {
	margin-right: .83vw;
}

footer .copyright {
	display: inline-flex;
	align-items: center;
}

footer .copyright:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 53px;
	height: 1px;
	background: #fafafa;
	margin-right: .83vw;
}

footer .note {
	position: absolute;
	right: 0;
}

#app[page="home"] footer .note {
	display: none;
}

.error {
	font-family: 'overpass-mono';
	background: #C9433C;
	color: #fff;
	padding: .71em 1.43em;
	margin-bottom: 1.3vh;
}

.ic {
	display: inline-block;
	width: 2.22vh;
	height: 2.22vh;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	vertical-align: middle;
}

.ic.ic-s {
	width: 1.48vh;
	height: 1.48vh;
}

.ic.ic-l {
	width: 3.33vh;
	height: 3.33vh;
}

.ic.ic-behance {
	background-image: url(assets/images/bps_i_behance.svg);
}

.ic.ic-twitter {
	background-image: url(assets/images/bps_i_twitter.svg);
}

.ic.ic-instagram {
	background-image: url(assets/images/bps_i_instagram.svg);
}

.ic.ic-medium {
	background-image: url(assets/images/bps_i_medium.svg);
}

.ic.ic-close-b {
	background-image: url(assets/images/bps_i_close-b.svg);
}

.ic.ic-delete {
	background-image: url(assets/images/bps_i_delete.svg);
}

.ic.ic-warning {
	background-image: url(assets/images/bps_i_warning.svg);
}

.ic.ic-menu {
	background-image: url(assets/images/bps_i_menu.svg);
}

.ic.ic-arrow {
	background-image: url(assets/images/bps_i_arrow.svg);
}

.ic.ic-arrow-right {
	background-image: url(assets/images/bps_i_arrow-right.svg);
}

.ic.ic-checkout {
	background-image: url(assets/images/bps_i_checkout.svg);
}

.ic.ic-edit {
	background-image: url(assets/images/bps_i_edit.svg);
}

.ic.ic-paw {
	background-image: url(assets/images/bps_i_paw.svg);
}

.ic.ic-forward {
	background-image: url(assets/images/bps_i_forward.svg);
}

.ic.ic-cart {
	background-image: url(assets/images/bps_i_cart.svg);
}

.ic.ic-ticket {
	background-image: url(assets/images/bps_i_ticket.svg);
}

.ic.ic-close {
	background-image: url(assets/images/bps_i_close.svg);
}

.ic.ic-cart-circle {
	background-image: url(assets/images/bps_i_cart-circle.svg);
}

.ic.ic-chevron-r {
	background-image: url(assets/images/bps_i_chevron-r.svg);
}

.ic.ic-chevron-l {
	background-image: url(assets/images/bps_i_chevron-l.svg);
}

.ic.ic-chevron-left {
	background-image: url(assets/images/bps_i_chevron-left.svg);
}

.ic.ic-chevron-right {
	background-image: url(assets/images/bps_i_chevron-right.svg);
}

.ic.ic-chevron-bottom {
	background-image: url(assets/images/bps_i_chevron-b.svg);
}

.ic.ic-check {
	background-image: url(assets/images/bps_i_check.svg);
}

.ic.ic-link {
	background-image: url(assets/images/bps_i_link.svg);
}

.ic.ic-facebook {
	background-image: url(assets/images/bps_i_facebook.svg);
}

.ic.ic-pinterest {
	background-image: url(assets/images/bps_i_pinterest.svg);
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}

@keyframes wobbleDown {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(.95);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes wobbleUp {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.02);
	}
	100% {
		transform: scale(1);
	}
}

@media screen and (max-width: 1023px) {
	html, body {
		font-size: 14px;
	}
	body {
		position: relative;
		height: 100%;
		overflow: auto;
	}
	#app {
		display: block;
		padding: 94px 0 0;
	}
	#app:not([page="home"]):before {
		content: '';
		position: fixed;
		width: 100%;
		height: 314px;
		left: 0;
		bottom: -157px;
		background: rgb(32,142,127);
		background: -moz-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		background: -webkit-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		background: radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#208e7f",endColorstr="#208e7f",GradientType=1);
		pointer-events: none;
	}
	.overlay {
		align-items: flex-start;
		overflow: auto;
	}
	.toast {
		bottom: 272px;
		width: 100%;
		max-width: 382px;
		z-index: 999999;
		padding: 12px 36px 12px 46px;
		background-position: 8px 10px;
		background-size: 30px 30px;
	}
	.toast .toast-title {
		font-size: 16px;
	}
	.toast .toast-content {
		margin: 8px 0 16px;
		font-size: 12px;
	}
	.toast .toast-actions button {
		opacity: 1;
		font-size: 12px;
		width: 134px;
		height: 38px;
		margin: 0;
		padding: 0;
	}
	.toast .toast-actions button:first-child {
		margin-right: 16px;
	}
	.newsletter-bar {
		flex-flow: column nowrap;
		left: auto;
		top: auto;
		bottom: 76px;
		align-items: flex-start;
		justify-content: flex-start;
		z-index: 9999998;
		width: 32px;
		height: 48px;
		padding: 8px 0 16px;
		transform: none;
		overflow: hidden;
		transition: width .16s ease-out, height .16s ease-out, bottom .16s ease-out;
		transform-origin: center right;
		animation: wobbleDown .16s ease-in-out .16s forwards;
	}
	#app[loader] .newsletter-bar {
		display: none;
	}
	.newsletter-bar:before {
		content: '';
		position: absolute;
		top: 16px;
		left: 10px;
		width: 14px;
		height: 14px;
		background: url(./assets/images/bps_i_ticket.svg) no-repeat center/contain;
	}
	#app[newsletter] .newsletter-bar {
		width: 225px;
		height: 242px;
		bottom: 20px;
		transform: none;
		animation: wobbleUp .16s ease-in-out .16s forwards;
	}
	.newsletter-bar > * {
		width: 225px;
		opacity: 0;
		transition: opacity .16s ease-out;
		padding: 0 30px 0 16px;
	}
	#app[newsletter] .newsletter-bar > * {
		opacity: 1;
	}
	#app[newsletter] .newsletter-bar[success] > *:not(.newsletter-confirmation) {
		opacity: 0;
		transition: opacity .16s ease-in .5s;
	}
	.newsletter-bar .newsletter-confirmation {
		width: 225px;
		padding: 0 16px;
		text-align: left;
	}
	.newsletter-bar form {
		flex-flow: column nowrap;
		margin: 16px 0 12px;
	}
	.newsletter-bar form input,
	.newsletter-bar form button {
		width: 100%;
		height: 38px;
	}
	.newsletter-bar form button {
		font-size: 12px;
	}
	.newsletter-thumb .newsletter-teaser,
	.newsletter-bar .newsletter-teaser {
		font-size: 12px;
		padding-left: 32px;
	}
	.newsletter-thumb .newsletter-teaser .ic,
	.newsletter-bar .newsletter-teaser .ic {
		width: 14px;
		height: 14px;
	}
	.newsletter-bar .newsletter-disclaimer {
		font-size: 10px;
	}
	header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 94px;
		padding: 0 32px;
		background: #221F1F;
		z-index: 100;
	}
	#app[page="checkout"] header {
		justify-content: flex-start;
	}
	header .btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: none;
		border: none;
		width: 48px;
		height: 48px;
		padding: 0;
	}
	header img {
		width: 142px;
		height: auto;
	}
	header img.store-logo {
		width: 86px;
		margin-left: 24px;
	}
	footer {
		position: relative;
		flex-flow: column nowrap;
		padding: 30px 32px;
		font-size: 12px;
		bottom: auto;
		left: auto;
		right: auto;
	}
	#app[loader] footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}
	footer .ic.social {
		width: 48px;
		height: 48px;
		background-size: 16px 16px;
		margin: 0 3px;
	}
	footer .copyright {
		display: block;
		margin-top: 12px;
		text-align: center;
	}
	footer .copyright:before {
		display: block;
		margin: 0 auto 12px;
	}
	footer .note {
		display: none;
	}
	.ic {
		width: 24px;
		height: 24px;
	}
	#app:not([page="checkout"]) .view .view-title {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		height: 74px;
		background: #221F1F;
		z-index: 100;
		padding: 2px 32px 0;
	}
	.view .view-title h1 {
		display: none;
	}
	.view .view-title h3 {
		font-size: 30px;
		font-weight: 800;
		margin-top: 4px;
		letter-spacing: -.08em;
	}
	.view .view-title .collection {
		padding: 0;
	}
	.view .view-title .collection,
	.view .view-title .collection span,
	.view .view-title .collection a {
		display: flex;
		align-items: center;
		text-align: left;
		font-size: 12px;
	}
	.view .view-title .collection img {
		width: 52px;
		vertical-align: middle;
		margin: 0 8px 0 6px;
	}
	[page="home"] .side:after {
		display: none;
	}
	nav {
		position: fixed;
		display: flex;
		flex-flow: column nowrap;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:#221F1F;
		z-index: 9999;
		overflow: auto;
		padding: 24px 0 30px;
		transform: translateX(-100%);
		transition: transform .24s ease-out;
		will-change: transform;
		-webkit-overflow-scrolling: touch;
	}
	nav.cart-panel {
		transform: translateX(100%);
	}
	#app[cart-opened] .cart-panel,
	#app[menu-opened] .menu {
		transform: none;
	}
	nav .menu-header {
		padding: 0 32px;
		margin-bottom: 36px;
	}
	nav .menu-header .menu-title {
		display: flex;
		align-items: center;
		font-size: 21px;
		font-weight: 900;
		text-transform: uppercase;
	}
	nav .menu-header .menu-title .ic {
		margin-right: 8px;
	}
	.cart-panel .menu-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;
	}
	nav .menu-header .btn {
		display: inline-flex;
		align-items: center;
		padding: 0 14px;
		height: 48px;
		background: none;
		border: none;
		font: bold 16px/1 'Inter';
	}
	nav .menu-header .btn .ic {
		width: 14px;
		height: 14px;
		margin-right: 8px;
	}
	nav .menu-link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 64px;
		padding: 0 32px 0 64px;
		font-size: 16px;
		font-family: 'Inter';
	}
	nav .menu-link.router-link-exact-active {
		background: rgba(32, 142, 127, 0.77);
		font-weight: bold;
	}
	nav .menu-link span .ic {
		width: 16px;
		height: 16px;
		margin-right: 12px;
	}
	.view .view-title .collection .btn,
	nav .menu-link .btn {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		background: none;
		border: none;
		padding: 0;
	}
	.view .view-title .collection .btn .ic,
	nav .menu-link .btn .ic {
		width: 10px;
		height: 10px;
	}
	nav .menu-link .btn {
		background: #208E7F;
		border: 5px solid #155C52;
	}
	nav .menu-cart {
		text-align: center;
		margin-top: 32px;
	}
	nav .menu-cart a {
		display: inline-flex;
		align-items: center;
		font-size: 12px;
		font-family: 'Inter';
		text-transform: uppercase;
		color: #EDBA52;
	}
	nav .menu-cart a .ic {
		width: 36px;
		height: 36px;
		margin-left: 6px;
	}
	nav .menu-logos {
		margin-top: 32px;
	}
	nav .menu-logos img {
		display: block;
		height: 38px;
		margin: 26px auto;
	}
	nav footer {
		padding: 0;
	}
}

@media screen and (max-width: 410px) {
	header {
		padding: 0 24px;
	}
	nav .menu-header {
		padding: 0 24px;
	}
	nav .menu-link {
		padding: 0 32px 0 40px;
	}
	nav footer {
		padding: 0 24px;
	}
	.view .view-title h3 {
		font-size: 21px;
	}
	.view .view-title .collection, .view .view-title .collection span, .view .view-title .collection a {
		font-size: 10px;
	}
	nav .menu-header .menu-title {
		font-size: 16px;
	}
	#app:not([page="checkout"]) .view .view-title {
		padding: 0 24px;
	}
	.toast .toast-actions button {
		width: 110px;
		font-size: 10px;
		padding: 0;
	}
	.toast .toast-actions button:first-child {
		margin-right: 8px;
	}
}
</style>
