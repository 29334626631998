import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/categories/:id',
      name: 'category',
      component: () => import(/* webpackChunkName: "category" */ './views/Category.vue')
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import(/* webpackChunkName: "cart" */ './views/Cart.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import(/* webpackChunkName: "checkout" */ './views/Checkout.vue')
    },
    {
      path: '/products/:id',
      name: 'alt-product',
      component: () => import(/* webpackChunkName: "alt-product" */ './views/Product.vue')
    },
    {
      path: '/products/:id/:variant',
      name: 'alt-product-variant',
      component: () => import(/* webpackChunkName: "alt-product-variant" */ './views/Product.vue')
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    },
    {
      path: '/:id',
      name: 'product',
      component: () => import(/* webpackChunkName: "product" */ './views/Product.vue')
    },
    {
      path: '/:id/:variant',
      name: 'product-variant',
      component: () => import(/* webpackChunkName: "product-variant" */ './views/Product.vue')
    },
    {
      path: '*',
      name: '404-default',
      component: () => import(/* webpackChunkName: "404-default" */ './views/404.vue')
    }
  ]
})
