import Vue from 'vue'
import Vuex from 'vuex'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'

firebase.initializeApp({
    apiKey: "AIzaSyCRBMnAI-Bbzc2f1EW00HwEBzlkfr7KYFs",
	authDomain: "bps-store.firebaseapp.com",
	databaseURL: "https://bps-store.firebaseio.com",
	projectId: "bps-store",
	storageBucket: "bps-store.appspot.com",
	messagingSenderId: "86827458667"
})

firebase.firestore().settings({ timestampsInSnapshots: true })

Vue.use(Vuex)

const isItem = item => {
    return typeof item === 'object' && !Array.isArray(item) && Object.keys(item).length === 3 && typeof item.quantity === 'number' && typeof item.id === 'number' && typeof item.variant_id === 'number' && item.quantity > 0 && item.quantity <= 9999
}

const areItems = items => {
    return Array.isArray(items) && items.findIndex(item => !isItem(item)) === -1
}

window.loadCartItems = () => {
    if(window.localStorage) {
        let items = window.localStorage.getItem('CART_ITEMS')
        if(items) {
            try {
                items = JSON.parse(items)
                if(areItems(items)) {
                    return items
                }
            }
            catch(e) {
                // eslint-disable-next-line
                console.log(e)
            }
        }
    }
    return []
}

const getCart = (products = [], variants = [], items = [], shipping, save = false) => {
    const cart = {
        items: [],
        products: [],
        item_count: 0
    }
    let total = 0
    for(let i = 0; i < items.length; i++) {
        const item = items[i]
        const product = variants.find(p => p.variant_id === item.variant_id && p.id === item.id)
        if(!product) continue
        const details = products.find(p => p.id === product.sync_product_id)
        if(!details) continue
        const subtotal = parseFloat(product.retail_price) * item.quantity
        total += subtotal
        cart.items.push(item)
        cart.item_count += item.quantity
        cart.products.push(Object.assign({
            details: Object.assign(product.details, details),
            quantity: item.quantity,
            subtotal: subtotal.toFixed(2)
        }, product))
    }
    if(shipping) cart.shipping = shipping
    if(window.localStorage && save) window.localStorage.setItem('CART_ITEMS', JSON.stringify(cart.items))
    const totalExclVat = total / 1.23
    return Object.assign({
        vat: (totalExclVat * .23).toFixed(2),
        total_excl_vat: totalExclVat.toFixed(2),
        total_excl_shipping: total.toFixed(2),
        total: (total + (shipping ? parseFloat(shipping.rate) : 0)).toFixed(2)
    }, cart)
}

export const store = new Vuex.Store({
    state: {
        firebase,
        variants: [],
        categories: [
            {
                name: 'Accessories & Hats',
                slug: 'accessories',
                image: 'https://storage.googleapis.com/bps-store.appspot.com/products%2Fimages%2F1331772589.png?v=1560252718821',
                disabled: false
            },
            {
                name: 'Shirts \n& Hoodies',
                slug: 'shirts-hoodies',
                image: 'https://storage.googleapis.com/bps-store.appspot.com/products%2Fimages%2F1323705179.png?v=1560252718210',
                disabled: false
            },
            {
                name: 'Bottoms \n& Dresses',
                slug: 'bottoms-dresses',
                image: 'https://storage.googleapis.com/bps-store.appspot.com/products%2Fimages%2F1331854123.png?v=1560261334615',
                disabled: false
            },
        ],
        products: [],
        cart: null,
        isMobile: window.innerWidth < 1024
    },
    mutations: {
        setProducts(state, products) {
            state.products = products || []
            if(state.variants.length && state.products.length) state.cart = getCart(state.products, state.variants, state.cart ? state.cart.items : [], state.cart ? state.cart.shipping : null)
        },
        setVariants(state, variants) {
            state.variants = variants || []
            if(state.variants.length && state.products.length) state.cart = getCart(state.products, state.variants, state.cart ? state.cart.items : [], state.cart ? state.cart.shipping : null)
        },
        addItemToCart(state, item) {
            if(state.cart && isItem(item)) {
                const index = state.cart.items.findIndex(i => i.variant_id === item.variant_id)
                if(index > -1) state.cart.items[index].quantity += item.quantity
                else state.cart.items.push(item)
                state.cart = getCart(state.products, state.variants, state.cart.items, state.cart.shipping, true)
            }
        },
        retrieveCart(state) {
            if(state.variants.length && state.products.length) state.cart = getCart(state.products, state.variants, window.loadCartItems(), null, true)
        },
        updateItem(state, item) {
            if(state.cart && isItem(item)) {
                const index = state.cart.items.findIndex(i => i.variant_id === item.variant_id)
                if(index > -1) {
                    state.cart.items[index] = item
                    state.cart = getCart(state.products, state.variants, state.cart.items, state.cart.shipping, true)
                }
            }
        },
        removeItem(state, itemId) {
            if(state.cart) {
                const index = state.cart.items.findIndex(i => i.variant_id === itemId)
                if(index > -1) {
                    const product = state.cart.products.find(p => p.variant_id === itemId)
                    if(typeof window.ga === 'function') {
                        window.ga('ec:addProduct', {
                            'id': product.details.id,
                            'name': product.details.name,
                            'category': product.details.category,
                            'variant': product.name,
                            'price': product.retail_price,
                            'quantity': product.quantity
                        })
                        window.ga('ec:setAction', 'remove')
                        window.ga('send', 'event', 'UX', 'click', `remove from cart`)
                    }
                    state.cart.items.splice(index, 1)
                    state.cart = getCart(state.products, state.variants, state.cart.items, state.cart.shipping, true)
                }
            }
        },
        emptyCart(state) {
            state.cart = getCart(state.products, state.variants, [], null, true)
        },
        setShipping(state, shipping) {
            if(state.cart && shipping) {
                state.cart = getCart(state.products, state.variants, state.cart.items, shipping, true)
            }
        },
        setMobile(state, value) {
            state.isMobile = value
        }
    },
    getters: {
        getDb(state) {
            return state.firebase.firestore
        },
        getAuth(state) {
            return state.firebase.auth
        },
        getFunctions(state) {
            return state.firebase.functions()
        },
        getVariants(state) {
            return state.variants
        },
        getCategories(state) {
            return state.categories
        },
        getProducts(state) {
            return state.products
        },
        getCart(state) {
            return state.cart
        },
        getMobile(state) {
            return state.isMobile
        }
    }
})

window.addEventListener('resize', () => {
    store.commit('setMobile', window.innerWidth < 1024)
})

firebase.firestore().collection('products').orderBy('name').onSnapshot(products => {
    store.commit('setProducts', products.docs.map(product => product.data()))
})

firebase.firestore().collection('variants').orderBy('sync_product_id').onSnapshot(variants => {
    store.commit('setVariants', variants.docs.map(variant => {
        const v = variant.data()
        return v
    }))
})
