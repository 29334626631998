<template>
  <div ref="container" class="fireflies">
    <canvas id="canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
/* global createjs */

export default {
  name: 'Fireflies',
  props: {
    
  },
  data() {
    return {
      running: false
    }
  },
  computed: {
    canvas() {
      return (this.$refs && this.$refs.canvas) || null
    }
  },
  watch: {
    canvas(c) {
      if(!this.running && c) this.initFireflies()
    }
  },
  methods: {
    initFireflies() {
      const canvas = this.$refs.canvas
      const stage = new createjs.Stage(canvas)
      this.stage = stage
      let w = window.innerWidth
      let h = window.innerHeight

      const STAR_COLORS = ['Salmon', 'White', 'Green', 'Pale-Blue', 'Pink', 'Purple']
      const STAR_GLOW_SPEED = 1000
      const STAR_GLOW_INTENSITY = .6
      const STAR_SIZE_MIN = .2
      const STAR_SIZE_MAX = 1.2
      const STAR_COUNT = 0

      const PARTICLE_X_MIN = .14
      const PARTICLE_X_MAX = .16
      const PARTICLE_SIZE_MIN = 10
      const PARTICLE_SIZE_MAX = 15
      const PARTICLE_SWAY_MIN = 1000
      const PARTICLE_SWAY_MAX = 1500
      const PARTICLE_COUNT = 30

      const FIREFLY_SIZE_MIN = 25
      const FIREFLY_SIZE_MAX = 50
      const FIREFLY_SWAY_MIN = 2500
      const FIREFLY_SWAY_MAX = 3500
      const FIREFLY_COUNT = 20

      const resize = () => {
        w = this.$refs.container.clientWidth
        h = this.$refs.container.clientHeight
        canvas.width = w
        canvas.height = h
      }

      const shuffle = (a,b,c,d) => {
        c=a.length;while(c)b=Math.random()*c--|0,d=a[c],a[c]=a[b],a[b]=d;return a
      }

      const getRandom = (max = 0, min = 0) => {
        return (Math.random() * max) + min
      }

      function Firefly(wait = 0) {
        const rand = Math.random()
        const x = rand * w
        const size = getRandom(FIREFLY_SIZE_MAX, FIREFLY_SIZE_MIN)
        const sway = getRandom(FIREFLY_SWAY_MAX, FIREFLY_SWAY_MIN)
        const circle = new createjs.Shape()
        circle.graphics
          .beginRadialGradientFill(["rgba(32,142,127,1)", "rgba(32,142,127,0.49)","rgba(32,142,127,0.0)"], [0, .1, 1], size, size, 0, size, size, size)
          .drawCircle(size, size, size)
        circle.x = x
        circle.y = h
        circle.alpha = 1 - rand
        stage.addChild(circle)
        createjs.Tween.get(circle, {loop: true})
          .wait(wait)
          .to({y: h * .3}, 8000)
          .to({alpha: 0, y: -size}, 6000, createjs.Ease.getPowOut(2))
        createjs.Tween.get(circle, {loop: true})
          .to({x: x - 48}, sway, createjs.Ease.getPowInOut(2))
          .to({x: x + 48}, sway, createjs.Ease.getPowInOut(2))
          .to({x}, sway, createjs.Ease.getPowInOut(2))
      }

      function Particle(wait = 0) {
        const x = getRandom(w * PARTICLE_X_MAX, w * PARTICLE_X_MIN)
        const size = getRandom(PARTICLE_SIZE_MAX, PARTICLE_SIZE_MIN)
        const sway = getRandom(PARTICLE_SWAY_MAX, PARTICLE_SWAY_MIN)
        const container = new createjs.Container()
        container.x = x
        container.y = h
        const circle = new createjs.Shape()
        circle.graphics
          .beginRadialGradientFill(["rgba(32,142,127,0.64)", "rgba(32,142,127,0.0)"], [0, 1], size, size, 0, size, size, size)
          .drawCircle(size, size, size)
        // const sheet = new createjs.SpriteSheet({
        //   images: ['https://store.blackpugstudio.com/images/star.png'],
        //   frames: {width: 64, height: 64, count: 10},
        //   framerate: getRandom(120, 30),
        //   animations: {
        //     fadeIn: [0, 1, 2, 3, 4],
        //     fadeOut: [4, 3, 2, 1, 0],
        //     shine: ['fadeIn', 5, 'fadeOut'],
        //     blink: shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
        //   }
        // })
        // const star = new createjs.Sprite(sheet, 'blink')
        // star.x = star.y = size / 2
        // star.scale = size / 64
        container.addChild(circle)
        // container.addChild(star)
        stage.addChild(container)
        createjs.Tween.get(container, {loop: true})
          .wait(wait)
          .to({y: getRandom(h * .45, h * .4), alpha: 0}, 10000, createjs.Ease.sineOut)
        createjs.Tween.get(container, {loop: true})
          .wait(wait)
          .to({x: x - getRandom(12, 6)}, sway, createjs.Ease.quadInOut)
          .to({x: x + getRandom(12, 6)}, sway, createjs.Ease.quadInOut)
          .to({x: x - getRandom(12, 6)}, sway, createjs.Ease.quadInOut)
          .to({x: x + getRandom(12, 6)}, sway, createjs.Ease.quadInOut)
          .to({x}, sway, createjs.Ease.quadInOut)
      }

      function Star(x = 0, y = 0) {
        const randX = (w / (STAR_COUNT * 2)) * (.5 - Math.random())
        const randY = (h / (STAR_COUNT * 2)) * (.5 - Math.random())
        const circle = new createjs.Shape()
        circle.graphics
          .beginFill(STAR_COLORS[getRandom(STAR_COLORS.length) | 0])
          .drawCircle(0, 0, getRandom(STAR_SIZE_MAX, STAR_SIZE_MIN))
        circle.x = ((w / STAR_COUNT) * x) + randX
        circle.y = ((h / STAR_COUNT) * y) + randY
        circle.alpha = 0
        stage.addChild(circle)
        const speed = getRandom(2000, 250)
        createjs.Tween.get(circle, {loop: true})
          .wait(getRandom(STAR_GLOW_SPEED))
          .to({alpha: getRandom(STAR_GLOW_INTENSITY)}, speed)
          .to({alpha: 0}, speed)
      }

      window.addEventListener('resize', resize)

      resize()

      for(let i = 0; i < PARTICLE_COUNT; i++) {
        Particle(i * 600)
      }

      for(let i = 0; i < FIREFLY_COUNT; i++) {
        Firefly(i * ((Math.random() * 1000) + 500))
      }

      for(let i = 1; i <= STAR_COUNT; i++) {
        for(let j = 1; j <= STAR_COUNT; j++) {
          Star(i, j)
        }
      }

      createjs.Ticker.timingMode = createjs.Ticker.RAF
      createjs.Ticker.addEventListener("tick", stage)
      this.running = true
    }
  },
  mounted: function() {
    this.$nextTick(this.initFireflies.bind(this))
  },
  beforeDestroy: function() {
    if(this.stage) {
      this.stage.removeAllEventListeners()
      this.stage.removeAllChildren()
    }
  }
}
</script>

<style>
.fireflies {
  position: relative;
  height: 80vh;
  overflow: hidden;
  pointer-events: none;
}

.fireflies:after {
  content: '';
  position: absolute;
  top: 25%;
  left: 0;
  width: 54.375vw;
  height: 200%;
  background: rgb(32,142,127);
  background: -moz-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
  background: -webkit-radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
  background: radial-gradient(circle, rgba(32,142,127,0.49) 0%, rgba(32,142,127,0) 49%, rgba(32,142,127,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#208e7f",endColorstr="#208e7f",GradientType=1);
  pointer-events: none;
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
