<template>
    <div class="view cart" v-if="cart">
		<div class="view-title" v-if="!isMobile">
			<div class="collection">2019 - 2020<br>Black Pug Studio Collection</div>
			<h1><i class="ic ic-cart"></i> Your shopping cart</h1>
		</div>
			<div class="container">
				<div class="items">
					<table>
						<thead>
							<tr v-if="!isMobile">
								<th>Product</th>
								<th>&nbsp;</th>
								<th>Price</th>
								<th>Quantity</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="product in cart.products" :key="product.id" class="item">
								<td class="item-image">
									<router-link :to="`/${product.details.slug}/${product.id}`">
										<img :src="product.images[0].url" :alt="product.name">
									</router-link>
									<div class="input-number" v-if="isMobile">
										<div class="input-buttons">
											<button @click="updateInput(product, -1)">-</button>
											<input :ref="`qty-${product.id}`" type="number" min="1" max="9999" :value="product.quantity" @blur="updateInput(product)" disabled>
											<button @click="updateInput(product, 1)">+</button>
										</div>
									</div>
									<a class="item-delete" @click="$store.commit('removeItem', product.variant_id)"><i class="ic ic-delete"></i> Delete</a>
								</td>
								<td class="item-info">
									<router-link class="item-name" :to="`/${product.details.slug}/${product.id}`">{{product.name}}</router-link>
									<div class="item-variant" v-if="product.details.color">COLOR VARIATION: {{product.details.color}}</div>
									<div class="item-variant" v-if="product.details.size">SIZE: {{product.details.size}}</div>
									<div class="item-price" v-if="isMobile">€{{product.retail_price}}</div>
								</td>
								<td class="item-price" v-if="!isMobile">€{{product.retail_price}}</td>
								<td class="item-quantity" v-if="!isMobile">
									<div class="input-number">
										<input :ref="`qty-${product.id}`" type="number" min="1" max="9999" :value="product.quantity" @blur="updateInput(product)">
										<div class="input-buttons">
											<button @click="updateInput(product, 1)">+</button>
											<button @click="updateInput(product, -1)">-</button>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="totals">
					<div class="total-line">
						<b>Subtotal:</b>
						<span>€{{cart.total}}</span>
					</div>
					<p>Your merchandise subtotal does not include shipping and handling.</p>
					<router-link class="btn" to="/checkout" :disabled="!cart.items.length" @click.native="closePanel">Proceed to checkout</router-link>
					<p>Do not worry! Our entire order process is 100% secure and is fulfilled by Printful® Inc., part of the DRAUGIEM GROUP. All online payments are secured and powered by Stripe Inc. This is an SSL-protected area protecting your sensitive information by encrypting the data you send to us.</p>
					<p>Black Pug Studio does not store or share personal information.</p>
					<p>You can also read our <a @click="getReturnPolicy">Return Policy</a>.</p>
				</div>
			</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: "cart",
	components: {},
	computed: {
		...mapGetters({
			cart: 'getCart',
			isMobile: 'getMobile'
		})
	},
	data() {
		return {
			
		}
	},
	watch: {
		cart() {
			this.viewCart()
			window.dispatchEvent(new CustomEvent('setLoading', {detail: {value: false}}))
		}
	},
	methods: {
		getReturnPolicy() {
			window.dispatchEvent(new CustomEvent('returnPolicy'))
		},
		viewCart() {
			if(typeof window.ga === 'function' && this.cart && this.cart.item_count && this.$route.path === '/cart') {
				this.cart.products.map((product, i) => {
					window.ga('ec:addImpression', {
						id: product.details.id,
						name: product.details.name,
						category: product.details.category,
						variant: product.name,
						position: i + 1
					})
				})
				window.ga('set', 'page', this.$route.path)
				window.ga('send', 'pageview')
			}
		},
		formatPrice(p) {
			if (isNaN(p)) return "0.00"
			return parseFloat(p).toFixed(2)
		},
		updateInput(product, n = 0) {
			const input = this.$refs[`qty-${product.id}`]
			if(input) {
				input[0].value = Math.max(Math.min(parseInt(input[0].value || 0) + n, 9999), 1)
				const quantity = parseInt(input[0].value)
				if(typeof window.ga === 'function' && product.quantity !== quantity) {
					window.ga('ec:addProduct', {
						'id': product.details.id,
						'name': product.details.name,
						'category': product.details.category,
						'variant': product.name,
						'price': product.retail_price,
						'quantity': Math.abs(product.quantity - quantity)
					})
					window.ga('ec:setAction', quantity > product.quantity ? 'add' : 'remove')
					window.ga('send', 'event', 'UX', 'click', `${quantity > product.quantity ? 'add to' : 'remove from'} cart`)
				}
				this.$store.commit('updateItem', { id: product.id, variant_id: product.variant_id, quantity })
			}
		},
		closePanel() {
			window.dispatchEvent(new CustomEvent('closePanel'))
		}
	},
	mounted: function() {
		this.viewCart()
	}
}
</script>

<style scoped>
.view {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
}

.view-title h1 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.view-title .ic {
	margin-right: 8px;
}

.container {
	position: relative;
	display: flex;
	justify-content: flex-end;
	z-index: 3;
	margin-top: 2.59vh;
	flex-grow: 1;
	padding-bottom: 7vh;
}

.totals {
	width: 23.18vw;
	margin-left: 3.02vw;
	padding-top: 4.4vh;
}

.totals .total-line {
	margin-bottom: 1.48vh;
}

.totals .total-line > * {
	font-size: 2.96vh;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1;
}

.totals .total-line span {
	color: #EDBA52;
	margin-left: 1.1vw;
}

.totals p {
	font-family: 'Inter';
	margin-bottom: 16px;
	font-size: 1.11vh;
}

.totals .btn {
	display: block;
	width: 17.7vw;
	height: 5.74vh;
	text-align: center;
	line-height: 5vh;
	border: 5px solid #BA9241;
	background: #EDBA52;
	color: #fff;
	font-size: 1.04vw;
	font-weight: 900;
	text-transform: uppercase;
	margin: 1.48vh 0;
}

.totals .btn[disabled] {
	opacity: .5;
	pointer-events: none;
}

.totals p a {
	color:#208E7F;
	text-decoration: underline;
}

.items {
	position: relative;
	width: 55vw;
	height: 65vh;
	overflow: auto;
}

.items th {
	font-weight: 400;
	font-family: 'Inter';
	text-transform: uppercase;
}

.items th,
.items td {
	border-bottom: 1px solid #208E7F;
	padding: 1.11vh 0;
	vertical-align: top;
}

.item .item-info {
	padding: 1.11vh 2.5vw;
}

.item .item-image {
	width: 6.66vw;
}

.item .item-image img {
	display: block;
	margin-bottom: 4px;
	width: 100%;
}

.item .item-info,
.item .item-price {
	padding-top: 2.96vh;
}

.item .item-name,
.item .item-price {
	font-size: 1.85vh;
	font-weight: 600;
	margin-top: 1.66vh;
}

.item .item-price {
	text-align: center;
}

.item .item-variant {
	font-family: 'Inter';
}

.item .item-price,
.item .item-quantity {
	width: 12.24vw;
}

.item .item-name:after {
	content: '';
	display: block;
	width: 10.42vw;
	height: 3px;
	margin: .93vh 0;
	background: #208E7F;
}

.item .input-number {
	display: flex;
	justify-content: center;
}

.item .input-number input {
	width: 5.74vh;
	height: 5.74vh;
	font: inherit;
	font-size: 1.85vh;
	color: #221F1F;
	text-align: center;
	margin-right: 4px;
	padding: 0;
}

.item .input-number .input-buttons {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
}

.item .input-number button {
	display: block;
	width: 2.59vh;
	height: 2.59vh;
	border: 1px solid;
	background: none;
	font-weight: 900;
	font-size: 1.8vh;
	opacity: .46;
	padding: 0;
}

.item .item-delete {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #C9433C;
	font-family: 'Inter';
	font-size: 1.11vh;
	margin-top: 4px;
}

.item .item-delete .ic {
	width: 1.11vh;
	height: 1.11vh;
	margin-right: 4px;
}

@media screen and (max-width: 1023px) {
	.view {
		display: block;
		height: auto;
	}
	.container {
		display: block;
		margin: 0 0 72px;
		padding: 0 32px;
	}
	.items {
		width: auto;
		height: auto;
		overflow: unset;
		border-top: 1px solid #208E7F;
	}
	.items td {
		padding: 12px 0;
	}
	.item .item-image {
		position: relative;
		width: 96px;
	}
	.item .item-image img {
		margin-bottom: 12px;
	}
	.item .input-number .input-buttons {
		flex-flow: row nowrap;
	}
	.item .input-number input {
		margin: 0;
		height: 28px;
		background: none;
		color: #fff;
		border: none;
		font-size: 20px;
	}
	.item .input-number button {
		width: 28px;
		height: 28px;
		font-size: 16px;
	}
	.item .item-info {
		position: relative;
		padding: 20px 16px;
	}
	.item .item-name {
		display: block;
		font-size: 13px;
		margin: 0;
	}
	.item .item-price {
		position: absolute;
		width: auto;
		text-align: right;
		font-size: 16px;
		padding: 0;
		bottom: 12px;
		right: 16px;
	}
	.item .item-name:after {
		width: 100%;
		margin: 12px 0;
		max-width: 192px;
	}
	.item .item-delete {
		position: absolute;
		font-size: 0;
		top: 58px;
		left: -22px;
		margin: 0;
	}
	.item .item-delete .ic {
		width: 12px;
		height: 12px;
	}
	.totals {
		width: auto;
		margin: 0;
		padding: 12px 0 0;
	}
	.totals .total-line {
		display: flex;
		justify-content: space-between;
		padding: 0 16px;
		margin: 0 0 8px;
	}
	.totals p {
		font-size: 12px;
		margin-bottom: 12px;
		padding: 0 16px;
	}
	.totals .btn {
		width: auto;
		height: 62px;
		font-size: 20px;
		line-height: 52px;
		margin: 16px 0;
	}
}

@media screen and (max-width: 410px) {
	.item .item-image {
		width: 64px;
	}
	.item .item-variant {
		font-size: 12px;
	}
	.totals .btn {
		font-size: 16px;
	}
}
</style>

