import Vue from 'vue'
import { store } from './store'
import router from './router'

import App from './App.vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper)

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame    ||
  function( callback ){
    window.setTimeout(callback, 1000 / 60);
  }
})()

Vue.mixin({
  methods: {
    scrollToY(scrollTargetY = 0, element = null, cb, speed = 2000, easing = 'easeOutSine') {
      const scrollY = element ? element.scrollTop : (window.scrollY || document.documentElement.scrollTop)
      let currentTime = 0
      const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8))
      const easingEquations = {
        easeOutSine: (pos) => {
          return Math.sin(pos * (Math.PI / 2))
        },
        easeInOutSine: (pos) => {
          return (-0.5 * (Math.cos(Math.PI * pos) - 1))
        },
        easeInOutQuint: (pos) => {
          if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(pos, 5)
          }
          return 0.5 * (Math.pow((pos - 2), 5) + 2)
        }
      }
      const tick = () => {
        currentTime += 1 / 60
        const p = currentTime / time
        const t = easingEquations[easing](p)
        if (p < 1) {
          window.requestAnimFrame(tick)
          if(element) element.scrollTop = scrollY + ((scrollTargetY - scrollY) * t)
          else window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
        } else {
          if(element) element.scrollTop = scrollTargetY
          else window.scrollTo(0, scrollTargetY)
          if(typeof cb === 'function') cb()
        }
      }
      tick()
    },
  }
})

router.beforeEach((to, from, next) => {
  if(!to || !from || !to.name || !from.name || !(from.name.startsWith('product') && to.name.startsWith('product'))) window.scrollTo(0, 0)
  next()
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
