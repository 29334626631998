<template>
  <span class="date-format">{{formatted}}</span>
</template>

<script>
export default {
	name: "DateFormat",
	props: {
		date: {
			type: Date
		},
		format: {
			type: String,
			default: "%Y-%m-%d %H:%i:%s"
		}
	},
	computed: {
    formatted() {
      return this.formatDate(this.date, this.format)
    }
  },
	methods: {
		formatDate(date, format) {
			date = new Date(date)
			if (date.toString() === "Invalid Date" || isNaN(date.getTime()))
				return ""
			var months = [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			]
			var days = [
				"Sunday",
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday"
			]
			var month = date.getMonth() + 1
			month = (month < 10 ? "0" : "") + month
			var day = date.getDate()
			day = (day < 10 ? "0" : "") + day
			var hours = date.getHours()
			hours = (hours < 10 ? "0" : "") + hours
			var min = date.getMinutes()
			min = (min < 10 ? "0" : "") + min
			var seconds = date.getSeconds()
			seconds = (seconds < 10 ? "0" : "") + seconds
			format = format || "Y-m-d H:i:s"
			return format.replace(/%([a-zA-Z0-9]+)/g, function(m, v) {
				switch (v) {
					case "y":
						return `${date.getFullYear()}`.substr(2)
					case "Y":
						return date.getFullYear()
					case "m":
						return month
					case "lm":
						return months[date.getMonth()].substr(0, 3)
					case "Lm":
						return months[date.getMonth()]
					case "d":
						return day
					case "ld":
						return days[date.getDay()].substr(0, 3)
					case "Ld":
						return days[date.getDay()]
					case "h":
						var h = hours % 12
						return h < 10 ? "0" + h : h
					case "H":
						return hours
					case "i":
						return min
					case "s":
						return seconds
					case "a":
						return date.getHours() >= 12 ? "P.M." : "A.M."
					default:
						return ""
				}
			})
		}
	}
}
</script>

<style scoped>
.image-input:after {
	content: "";
	display: block;
	clear: both;
}

.image-container,
.image-info {
	position: relative;
	float: left;
	width: 50%;
	height: 144px;
	box-sizing: border-box;
	padding: 12px 18px;
	overflow: hidden;
	font-size: 10px;
	letter-spacing: 0.05em;
}

.image-input[simple] .image-container {
	width: 100%;
}

.image-input[simple] .image-info {
	display: none;
}

.image-input[round] .image-container {
	width: 144px;
	border-radius: 50%;
}

.image-container {
	border: 1px solid #030303;
	background-color: #030303;
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.image-input[invalid] .image-container {
	border-color: #ff6666;
}

.image-container .image-preview,
.image-container input {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.image-container input {
	opacity: 0;
	z-index: 2;
	height: auto;
	cursor: pointer;
}

.image-container .placeholder .ic {
	margin-bottom: 4px;
}

.image-info .max {
	position: absolute;
	left: 18px;
	bottom: 12px;
	right: 18px;
}
</style>
